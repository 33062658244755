import { dispatchTrackingEvent } from "~assets/global/tracking/tracking-event";
import { ANCHOR_TYPE, handleDelayedScroll, isShownAnchorActive, setAnchor } from "~assets/frontend/_util/anchor-scrolling";
export const compAccordion = () => {
    const accordionComponents = document.querySelectorAll("[data-inject='comp-accordion']");
    accordionComponents.forEach((accordionComponent) => {
        const accordionHeader = accordionComponent.querySelector('[data-accordion="header"]');
        if (accordionHeader && accordionHeader instanceof HTMLElement) {
            accordionHeader.addEventListener("click", toggleAccordion);
            if (isShownAnchorActive(accordionHeader.id)) {
                changeAccordion(accordionHeader);
            }
            handleDelayedScroll(accordionHeader.id);
        }
    });
};
const toggleAccordion = (e) => {
    const target = e.currentTarget instanceof HTMLElement && e.currentTarget;
    if (!target)
        return;
    const ui_action_value = target.getAttribute("aria-expanded") === "false" ? "expand" : "collapse";
    const trackingEvent = {
        name: "ui",
        payload: {
            ui_function: "toggle",
            ui_action: ui_action_value,
            ui_component: "comp-accordion",
            ui_target: e.currentTarget.textContent,
        },
    };
    dispatchTrackingEvent(trackingEvent);
    setAnchor(ANCHOR_TYPE.SHOW, target.id);
    changeAccordion(target);
};
const changeAccordion = (target) => {
    const accordionBodyId = target.getAttribute("aria-controls");
    const showAccordionBody = target.getAttribute("aria-expanded") === "false";
    if (accordionBodyId) {
        if (showAccordionBody) {
            const accordionGroup = target.getAttribute("data-group");
            if (accordionGroup) {
                const relatedAccordions = document.querySelectorAll(`[data-group="${accordionGroup}"]`);
                relatedAccordions.forEach((relatedAccordion) => {
                    var _a;
                    relatedAccordion.setAttribute("aria-expanded", "false");
                    const icon = relatedAccordion.querySelector(".icon");
                    if (icon) {
                        icon.classList.remove("icon-minus");
                        icon.classList.add("icon-plus");
                    }
                    const relatedAccordionBodyId = relatedAccordion.getAttribute("aria-controls");
                    if (relatedAccordionBodyId) {
                        (_a = document.getElementById(relatedAccordionBodyId)) === null || _a === void 0 ? void 0 : _a.setAttribute("hidden", "");
                    }
                });
            }
            const accordionBody = document.getElementById(accordionBodyId);
            if (accordionBody) {
                target.setAttribute("aria-expanded", "true");
                const icon = target.querySelector(".icon");
                if (icon) {
                    icon.classList.remove("icon-plus");
                    icon.classList.add("icon-minus");
                }
                accordionBody.removeAttribute("hidden");
            }
        }
        else {
            const accordionBody = document.getElementById(accordionBodyId);
            if (accordionBody) {
                target.setAttribute("aria-expanded", "false");
                const icon = target.querySelector(".icon");
                if (icon) {
                    icon.classList.remove("icon-minus");
                    icon.classList.add("icon-plus");
                }
                accordionBody.setAttribute("hidden", "");
            }
        }
    }
};
