import React from "react";
import { NavigationItemInline } from "~assets/frontend/frame/frm-navigation/components/NavigationItemInline";
import { NavigationItemNormal } from "~assets/frontend/frame/frm-navigation/components/NavigationItemNormal";
import { NavigationItemPreview } from "~assets/frontend/frame/frm-navigation/components/NavigationItemPreview";
import { NavigationItemExternal } from "~assets/frontend/frame/frm-navigation/components/NavigationItemExternal";
export const NavigationItem = (props) => {
    return (React.createElement("li", { className: `w-full` },
        props.display === "normal" && React.createElement(NavigationItemNormal, Object.assign({}, props)),
        props.display === "external" && React.createElement(NavigationItemExternal, Object.assign({}, props)),
        props.display === "inline" && props.layer && React.createElement(NavigationItemInline, Object.assign({}, props)),
        props.display === "preview" && React.createElement(NavigationItemPreview, Object.assign({}, props))));
};
