import React, { useState } from "react";
import { NavigationOverlay } from "~assets/frontend/frame/frm-navigation/components/NavigationOverlay";
import { preventScrolling } from "~assets/frontend/_util/preventScrolling";
import { NavigationList } from "~assets/frontend/frame/frm-navigation/components/NavigationList";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { createPortal } from "react-dom";
import Translator from "bazinga-translator";
import { WhiteIconButton } from "~assets/frontend/elements/button/Buttons";
import { getId } from "~assets/frontend/_util/getId";
export const MainNavigation = ({ items }) => {
    const headerItems = items.filter((item) => item.priority <= 50);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [detail, setDetail] = useState(null);
    const showMenu = (locationId) => {
        setSelectedLocation(locationId);
    };
    const close = () => {
        setSelectedLocation(null);
        setDetail(null);
    };
    preventScrolling(!!selectedLocation);
    let activeTree = "";
    const activeElementIndicator = document.querySelector("[data-active-page]");
    if (activeElementIndicator) {
        activeTree = activeElementIndicator.getAttribute("data-active-page") || "";
    }
    return (React.createElement("div", null,
        React.createElement("nav", { "aria-label": Translator.trans("frm-navigation.aria.short-navigation", {}, "frontend") }, headerItems.length > 0 && (React.createElement("ul", { className: "flex list-none" }, headerItems.map((navItem) => {
            const navItemIsActive = navItem.title.trim() === activeTree.trim();
            return (React.createElement("li", { key: navItem.locationId },
                navItem.href && (React.createElement("a", { className: `${navItemIsActive
                        ? "text-red-100 underline underline-offset-8 dark:text-white group-[.condensed]:dark:text-red-100"
                        : ""} headline-2 mb-0 inline-block p-12 decoration-1 transition-colors hover:text-red-100 hover:no-underline dark:hover:text-white dark:hover:underline dark:hover:underline-offset-8 group-[.condensed]:dark:hover:text-red-100 group-[.condensed]:dark:hover:no-underline`, href: navItem.href }, navItem.title)),
                !navItem.href && (React.createElement("button", { className: `${navItemIsActive
                        ? "text-red-100 underline underline-offset-8 dark:text-white group-[.condensed]:dark:text-red-100"
                        : ""} headline-2 mb-0 inline-block p-12 decoration-1 transition-colors hover:text-red-100 hover:no-underline dark:hover:text-white dark:hover:underline dark:hover:underline-offset-8 group-[.condensed]:dark:hover:text-red-100 group-[.condensed]:dark:hover:no-underline`, onClick: () => {
                        showMenu(navItem.locationId);
                    } }, navItem.title))));
        })))),
        createPortal(React.createElement(MotionConfig, { transition: { duration: 0.6, ease: "easeOut" } },
            React.createElement(AnimatePresence, { onExitComplete: close }, selectedLocation && (React.createElement(NavigationOverlay, { onClose: close },
                React.createElement(motion.nav, { "aria-label": Translator.trans("frm-navigation.aria.main-navigation", {}, "frontend"), key: "children", initial: { x: "-100%" }, animate: { x: 0 }, exit: { x: "-100%" }, layout: "position", className: "fixed left-0 top-0 z-navigation flex h-screen" },
                    React.createElement(AnimatePresence, null, headerItems.length > 0 && (React.createElement(NavigationList, { additionalClassName: "max-w-[230px]", layer: 0, label: null, mainItems: headerItems, selected: selectedLocation, onSelect: setSelectedLocation, onClose: close, href: null, onDetail: setDetail }))),
                    React.createElement(AnimatePresence, null, detail && (React.createElement(motion.div, { initial: { x: "-100%" }, animate: { x: 0 }, exit: { x: `-${500}%` }, className: "h-full w-[398px] bg-white px-24 py-60" },
                        React.createElement("div", { className: "dark absolute right-0 top-0" },
                            React.createElement(WhiteIconButton, { _id: getId(), _name: "IconButtonStruct", onClick: close, label: Translator.trans("frm-navigation.aria.close", {}, "frontend"), iconIdentifier: "icon-close" })),
                        detail)))))))), document.body, "main-navigation")));
};
