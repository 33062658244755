import { motion } from "framer-motion";
import React from "react";
export const Loading = ({ label = "" }) => {
    return (React.createElement("div", { className: "flex flex-col items-center justify-center gap-24 py-48" },
        label.length > 0 && (React.createElement(motion.p, { className: "text-22 text-anthracite-100", animate: { opacity: [0.4, 1, 0.4] }, transition: {
                ease: "linear",
                repeat: Infinity,
                repeatDelay: 0.5,
                duration: 1.5,
                times: [0, 0.4, 0.7, 1],
                delay: 0,
            } }, label)),
        React.createElement("div", { className: "flex items-center justify-center gap-6" },
            React.createElement(motion.div, { className: "h-12 w-12 rounded-full bg-anthracite-10", animate: { opacity: [0, 1, 1, 0] }, transition: {
                    ease: "linear",
                    repeat: Infinity,
                    repeatDelay: 0.5,
                    duration: 1.5,
                    times: [0, 0.4, 0.7, 1],
                    delay: 0,
                } }),
            React.createElement(motion.div, { className: "h-12 w-12 rounded-full bg-anthracite-10", animate: { opacity: [0, 1, 1, 0] }, transition: {
                    ease: "linear",
                    repeat: Infinity,
                    repeatDelay: 0.5,
                    duration: 1.5,
                    times: [0, 0.35, 0.75, 1],
                    delay: 0.2,
                } }),
            React.createElement(motion.div, { className: "h-12 w-12 rounded-full bg-anthracite-10", animate: { opacity: [0, 1, 1, 0] }, transition: {
                    ease: "linear",
                    repeat: Infinity,
                    repeatDelay: 0.5,
                    duration: 1.5,
                    times: [0, 0.3, 0.8, 1],
                    delay: 0.4,
                } }))));
};
