import React from "react";
import { BREAKPOINT, getPriorityForBreakpoint, mapResponsiveImageSourceBreakpoint, minWidths } from "~assets/frontend/_util/breakpoints";
import { formatText } from "~assets/frontend/_util/hooks/useFormatter";
import { useImageStruct } from "~assets/frontend/_util/hooks/useImageStruct";
import { useBreakpoint } from "~assets/frontend/_util/hooks/useBreakpoint";
export const ResponsiveImage = ({ sizes, wrapperClass, dimImage = false, imgClass, pictureClass, _id, _name, fieldValue, caption, copyright, mask, additionalAttributes, }) => {
    const responsiveImageSources = useImageStruct({
        _id: _id,
        _name: _name,
        caption: caption,
        copyright: copyright,
        fieldValue: fieldValue,
        mask: mask,
    }, sizes);
    let breakpoints = responsiveImageSources
        ? Object.keys(responsiveImageSources).filter((key) => key !== "default" && key !== "_media-type" && key !== "alternativeText")
        : [];
    breakpoints.sort((a, b) => {
        return getPriorityForBreakpoint(b) - getPriorityForBreakpoint(a);
    });
    let breakpoint = useBreakpoint();
    let placeholderStyles = {};
    if (!responsiveImageSources) {
        const getPlaceholderSize = (size) => {
            // Possible combinations are:
            // - w and h
            // - w and r
            // - h and r
            let styles = {};
            if (size.w && size.h) {
                styles.aspectRatio = "" + size.w / size.h;
            }
            if (size.w && size.r) {
                styles.aspectRatio = "" + size.r;
            }
            if (size.h && size.r) {
                styles.aspectRatio = "" + size.r;
            }
            return styles;
        };
        if (sizes.extraLarge && breakpoint === BREAKPOINT.XL) {
            placeholderStyles = getPlaceholderSize(sizes.extraLarge);
        }
        else if (sizes.large && breakpoint === BREAKPOINT.LG) {
            placeholderStyles = getPlaceholderSize(sizes.large);
        }
        else if (sizes.medium && breakpoint === BREAKPOINT.MD) {
            placeholderStyles = getPlaceholderSize(sizes.medium);
        }
        else if (sizes.small && breakpoint === BREAKPOINT.SM) {
            placeholderStyles = getPlaceholderSize(sizes.small);
        }
        else if (sizes.extraSmall) {
            placeholderStyles = getPlaceholderSize(sizes.extraSmall);
        }
    }
    return (React.createElement("figure", Object.assign({ className: wrapperClass || "" }, additionalAttributes),
        React.createElement("picture", { className: pictureClass || "relative block" },
            responsiveImageSources && (React.createElement(React.Fragment, null,
                breakpoints.map((breakpoint, index) => {
                    const source = responsiveImageSources[breakpoint];
                    return (React.createElement("source", { key: index, srcSet: source === null || source === void 0 ? void 0 : source.src, width: source === null || source === void 0 ? void 0 : source.width, height: source === null || source === void 0 ? void 0 : source.height, type: (source === null || source === void 0 ? void 0 : source.mimeType) || "", media: breakpoint !== "extraSmall" ? `(min-width: ${minWidths[mapResponsiveImageSourceBreakpoint(breakpoint)]}px)` : undefined }));
                }),
                React.createElement("img", { src: responsiveImageSources.default.src, loading: "lazy", alt: responsiveImageSources.alternativeText, className: imgClass ? imgClass : dimImage ? "brightness-75" : "" }))),
            !responsiveImageSources && React.createElement("div", { className: "animate-pulse bg-anthracite-10", style: placeholderStyles })),
        caption && (React.createElement("figcaption", { className: `relative mt-12 w-0 min-w-full text-black-60 ${caption ? " pr-24" : ""}` },
            formatText(caption),
            copyright && (React.createElement("div", { className: "absolute right-0 top-0 cursor-help text-black-40", title: copyright }, "\u00A9")))),
        !caption && copyright && (React.createElement("figcaption", { className: "relative w-0 min-w-full text-black-60" },
            React.createElement("div", { className: "absolute right-0 top-12 cursor-help text-black-40", title: copyright }, "\u00A9")))));
};
