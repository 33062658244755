import { dispatchTrackingEvent } from "~assets/global/tracking/tracking-event";
export const trackLayoutShift = () => {
    new PerformanceObserver((entryList) => {
        for (const entry of entryList.getEntries()) {
            const layoutShift = entry;
            if (layoutShift.hadRecentInput && layoutShift.value >= 0.1) {
                const node = layoutShift["sources"][0].node;
                let componentValue = "removed";
                if (node) {
                    componentValue = node.nodeName + "-";
                    componentValue += node.textContent ? node.textContent.trim() : "";
                }
                const event = {
                    name: "ui",
                    payload: {
                        ui_component: componentValue,
                        ui_target: layoutShift.value.toString(),
                        ui_action: "layout-shift",
                        ui_function: "performance",
                    },
                };
                dispatchTrackingEvent(event);
            }
        }
    }).observe({ type: "layout-shift", buffered: true });
};
