import { registerTrackingEvent } from "~assets/global/tracking/tracking-event";
import { handleDefaultAnchorLinks } from "~assets/frontend/_util/anchor-scrolling";
export const inPageNavigation = () => {
    const inPageNavigation = document.getElementById("in-page-navigation");
    if (!inPageNavigation) {
        return;
    }
    const updateNavigation = (entries, observer) => {
        const enteringEntries = entries.filter((entry) => entry.isIntersecting);
        const leavingEntries = entries.filter((entry) => !entry.isIntersecting);
        leavingEntries.forEach((entry) => {
            const leavingEntry = document.querySelector("[href='#" + entry.target.id + "']");
            entry.target.classList.remove("relative", "before:block", "before:w-6", "before:bg-red-100", "before:h-full", "before:absolute", "before:top-0");
            if (leavingEntry) {
                leavingEntry.removeAttribute("data-active");
                leavingEntry.classList.remove("text-red-100");
                leavingEntry.classList.remove("underline");
            }
        });
        if (enteringEntries.length) {
            const activeId = enteringEntries[0].target.id;
            enteringEntries[0].target.classList.add("relative", "before:block", "before:w-6", "before:bg-red-100", "before:h-full", "before:absolute", "before:top-0");
            const oldActiveItem = document.querySelector("[data-active]");
            if (oldActiveItem) {
                oldActiveItem.removeAttribute("data-active");
                oldActiveItem.classList.remove("text-red-100");
                oldActiveItem.classList.remove("underline");
            }
            const activeItem = document.querySelector("[href='#" + activeId + "']");
            if (activeItem) {
                activeItem.setAttribute("data-active", "");
                activeItem.classList.add("text-red-100");
                activeItem.classList.add("underline");
            }
        }
    };
    const options = {
        rootMargin: "-30% 0% -70% 0%",
    };
    const observer = new IntersectionObserver(updateNavigation, options);
    for (const child of inPageNavigation.children) {
        if (!(child instanceof HTMLTemplateElement)) {
            inPageNavigation.removeChild(child);
        }
    }
    const inPageNavigationItems = document.querySelectorAll("[data-nav]");
    if (inPageNavigationItems.length === 0) {
        return;
    }
    const navTemplate = document.querySelector("#navigation-template");
    const navItemTemplate = document.querySelector("#navigation-item-template");
    const navLinkTemplate = document.querySelector("#navigation-link-template");
    if (!(navTemplate instanceof HTMLTemplateElement) ||
        !(navItemTemplate instanceof HTMLTemplateElement) ||
        !(navLinkTemplate instanceof HTMLTemplateElement)) {
        return;
    }
    const listDocument = navTemplate.content.cloneNode(true);
    if (!(listDocument instanceof DocumentFragment)) {
        return;
    }
    const list = listDocument.querySelector("ul");
    if (!(list instanceof HTMLElement)) {
        return;
    }
    inPageNavigationItems.forEach((item) => {
        const identifier = item.id;
        const title = item.getAttribute("data-nav");
        observer.observe(item);
        if (!identifier || !title) {
            return;
        }
        const listItemDocument = navItemTemplate.content.cloneNode(true);
        if (!(listItemDocument instanceof DocumentFragment)) {
            return;
        }
        const listItem = listItemDocument.firstElementChild;
        if (!(listItem instanceof HTMLLIElement)) {
            return;
        }
        const anchorLinkDocument = navLinkTemplate.content.cloneNode(true);
        if (!(anchorLinkDocument instanceof DocumentFragment)) {
            return;
        }
        const anchorLink = anchorLinkDocument.firstElementChild;
        if (anchorLink instanceof HTMLAnchorElement) {
            anchorLink.setAttribute("href", "#" + identifier);
            anchorLink.textContent = title;
            anchorLink.setAttribute("data-event", JSON.stringify({
                name: "ui",
                payload: { ui_component: "frm-in_page_navigation", ui_function: "navigate", ui_action: "link", ui_target: title },
            }));
            registerTrackingEvent(anchorLink);
            handleDefaultAnchorLinks([anchorLink]);
            listItem.appendChild(anchorLink);
        }
        else {
            return;
        }
        list.appendChild(listItem);
    });
    inPageNavigation.appendChild(listDocument);
    const listener = (event) => {
        if (event instanceof CustomEvent && "showCondensed" in event.detail) {
            if (event.detail.showCondensed) {
                inPageNavigation.classList.add("md:top-[75px]");
                inPageNavigation.classList.add("top-[99px]");
            }
            else {
                inPageNavigation.classList.remove("md:top-[75px]");
                inPageNavigation.classList.remove("top-[99px]");
            }
        }
    };
    document.body.addEventListener("header", listener);
};
