import { TRIGGER_SELECTOR_CALENDAR_APP, TRIGGER_SELECTOR_CAROUSEL, TRIGGER_SELECTOR_DEALERSEARCH, TRIGGER_SELECTOR_GOOGLE_MAPS, TRIGGER_SELECTOR_INTERACTIVE_TABLE, TRIGGER_SELECTOR_LIGHTBOX, TRIGGER_SELECTOR_LOCATIONS, TRIGGER_SELECTOR_OFFCANVAS, TRIGGER_SELECTOR_SAMPLEORDER, TRIGGER_SELECTOR_SEARCH, TRIGGER_SELECTOR_STAGE_CAROUSEL, } from "~assets/trigger";
import { inPageNavigation } from "~assets/frontend/frame/in-page-navigation/in-page-navigation";
import { breadcrumb } from "~assets/frontend/frame/breadcrumb/breadcrumb";
import Translator from "bazinga-translator";
// @ts-ignore
import translationsEN from "../translations/frontend.en.json";
// @ts-ignore
import translationsDe from "../translations/frontend.de.json";
import "~assets/global/tracking/tracking";
import frmHeader from "~assets/frontend/frame/frm-header/frm-header";
import { trackLayoutShift } from "~assets/frontend/_util/track-layout-shift";
import { frameNavigationLoader } from "~assets/frontend/frame/frm-navigation/navigation-loader";
import { modTabs } from "~assets/frontend/modules/mod-tabs/mod-tabs";
import { compAccordion } from "~assets/frontend/components/comp-accordion/comp-accordion";
import { anchorScrolling, handleDefaultAnchorLinks } from "~assets/frontend/_util/anchor-scrolling";
import { PolyfillUtilityNav } from "~assets/frontend/frame/frm-utility-nav/frm-utility-nav";
PolyfillUtilityNav();
// <editor-fold desc="Translator">
Translator.fallback = "de";
Translator.defaultDomain = "messages";
// Initial Language for storybook
Translator.locale = document.documentElement.lang.split("-")[0] || "de";
// Add frontend localizations
// Recursive function to add all translations
export const recAdd = (obj, domain, locale, prefix = "") => {
    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === "object") {
            recAdd(value, domain, locale, `${prefix}${key}.`);
        }
        else if (typeof value === "string") {
            Translator.add(`${prefix}${key}`, value, domain, locale);
        }
    }
};
recAdd(translationsEN, "frontend", "en");
recAdd(translationsDe, "frontend", "de");
// </editor-fold>
// <editor-fold desc="Frontend-Modules">
export function initFrontendModules() {
    inPageNavigation();
    frmHeader();
    frameNavigationLoader();
    breadcrumb();
    trackLayoutShift();
    // FIXME: Wenn man dne Chunk "global" nennt und da alles rein packt, dann bringts eh nix die imports nur zu laden, wenn man sie braucht..
    // weil sie dann...
    // immer geladen werden
    const searches = document.querySelectorAll(TRIGGER_SELECTOR_SEARCH);
    if (searches.length > 0) {
        import("./frontend/frame/frm-search/frm-search-loader" /* webpackChunkName: "frame" */).then(({ frameSearchLoader }) => {
            try {
                frameSearchLoader(searches);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    const stageCarousels = document.querySelectorAll(TRIGGER_SELECTOR_STAGE_CAROUSEL);
    if (stageCarousels.length > 0) {
        import("./frontend/modules/mod-stage_carousel/mod-stage_carousel-loader" /* webpackChunkName: "global" */).then(({ StageCarouselLoader }) => {
            try {
                StageCarouselLoader(stageCarousels);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    const interactiveTables = document.querySelectorAll(TRIGGER_SELECTOR_INTERACTIVE_TABLE);
    if (interactiveTables.length > 0) {
        import("./frontend/components/comp-interactive_table_filter/interactive-table-loader" /* webpackChunkName: "products" */).then(({ InteractiveTableLoader }) => {
            try {
                InteractiveTableLoader(interactiveTables);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    const carousels = document.querySelectorAll(TRIGGER_SELECTOR_CAROUSEL);
    if (carousels.length > 0) {
        import("./frontend/components/comp-carousel/comp-carousel-loader" /* webpackChunkName: "global" */).then(({ CarouselLoader }) => {
            try {
                CarouselLoader(carousels);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    const offCanvases = document.querySelectorAll(TRIGGER_SELECTOR_OFFCANVAS);
    if (offCanvases.length > 0) {
        import("./frontend/components/comp-offcanvas/comp-offcanvas-loader" /* webpackChunkName: "global" */).then(({ OffCanvasLoader }) => {
            try {
                OffCanvasLoader(offCanvases);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    const lightBoxes = document.querySelectorAll(TRIGGER_SELECTOR_LIGHTBOX);
    if (lightBoxes.length > 0) {
        import("./frontend/components/comp-lightbox/comp-lightbox-loader" /* webpackChunkName: "global" */).then(({ LightboxLoader }) => {
            try {
                LightboxLoader(lightBoxes);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    const tabs = document.querySelector("[data-inject='mod-tabs']");
    if (tabs !== null) {
        import("~assets/frontend/modules/mod-tabs/mod-tabs" /* webpackChunkName: "global" */).then(() => {
            modTabs();
        });
    }
    const accordion = document.querySelector("[data-inject='comp-accordion']");
    if (accordion !== null) {
        import("~assets/frontend/components/comp-accordion/comp-accordion" /* webpackChunkName: "global" */).then(() => {
            compAccordion();
        });
    }
    const maps = document.querySelectorAll(TRIGGER_SELECTOR_GOOGLE_MAPS);
    if (maps.length > 0) {
        import("./frontend/components/comp-google-maps/google-maps-loader" /* webpackChunkName: "tools" */).then(({ GoogleMapsLoader }) => {
            try {
                GoogleMapsLoader(maps);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    const calendars = document.querySelectorAll(TRIGGER_SELECTOR_CALENDAR_APP);
    if (calendars.length > 0) {
        import("~assets/frontend/components/comp-calendar-app/calendar-app-loader" /* webpackChunkName: "tools" */).then(({ CalendarAppLoader }) => {
            try {
                CalendarAppLoader(calendars);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    const locations = document.querySelectorAll(TRIGGER_SELECTOR_LOCATIONS);
    if (locations.length > 0) {
        import("./frontend/modules/mod-locations/mod-locations-loader" /* webpackChunkName: "tools" */).then(({ LocationsLoader }) => {
            try {
                LocationsLoader(locations);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    const dealersearches = document.querySelectorAll(TRIGGER_SELECTOR_DEALERSEARCH);
    if (dealersearches.length > 0) {
        import("./frontend/modules/mod-dealersearch/mod-dealersearch-loader" /* webpackChunkName: "tools" */).then(({ DealersearchLoader }) => {
            try {
                DealersearchLoader(dealersearches);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    const sampleOrders = document.querySelectorAll(TRIGGER_SELECTOR_SAMPLEORDER);
    if (sampleOrders.length > 0) {
        import("./frontend/modules/mod-sample_order/mod-sample_order-loader" /* webpackChunkName: "tools" */).then(({ SampleOrderLoader }) => {
            try {
                SampleOrderLoader(sampleOrders);
            }
            catch (e) {
                if (document.location.hostname.includes("localhost"))
                    console.error(e);
            }
        });
    }
    anchorScrolling(true);
    handleDefaultAnchorLinks();
}
initFrontendModules();
// </editor-fold>
