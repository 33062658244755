export const PolyfillUtilityNav = () => {
    if (/(ipad|iphone)/gi.test(navigator.userAgent)) {
        const utilityNav = document.querySelector('[data-inject="frm-utility-nav"]');
        document.body.addEventListener("click", (event) => {
            const target = event.target;
            if (target instanceof HTMLElement) {
                const outsideClick = target.closest('[data-inject="frm-utility-nav"]') === null;
                if (outsideClick) {
                    utilityNav === null || utilityNav === void 0 ? void 0 : utilityNav.removeAttribute("data-expanded");
                }
                else {
                    if (!(utilityNav === null || utilityNav === void 0 ? void 0 : utilityNav.hasAttribute("data-expanded"))) {
                        event.preventDefault();
                        utilityNav === null || utilityNav === void 0 ? void 0 : utilityNav.setAttribute("data-expanded", "");
                    }
                }
            }
        });
    }
};
