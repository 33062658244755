import { attachTrackingEventListener, registerTrackingEvent } from "~assets/global/tracking/tracking-event";
// Attach to event listened and push into Google Analytics
attachTrackingEventListener((event) => {
    if (event instanceof CustomEvent && "name" in event.detail) {
        const trackingEvent = event;
        if (typeof window.gtag === "function") {
            gtag("event", trackingEvent.detail.name, trackingEvent.detail.payload);
        }
        else if (window.dataLayer && typeof window.dataLayer.push === "function") {
            window.dataLayer.push(Object.assign({ event: trackingEvent.detail.name }, trackingEvent.detail.payload));
        }
    }
});
document.querySelectorAll("[data-event]").forEach((element) => {
    registerTrackingEvent(element);
});
// Tracking of UI elements rendered by twig - thus beeing static ;-)
