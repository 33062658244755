export const dispatchTrackingEvent = (eventPayload) => {
    document.body.dispatchEvent(new CustomEvent("tracking", { detail: eventPayload }));
};
export const attachTrackingEventListener = (listener) => {
    document.body.addEventListener("tracking", listener);
};
export const registerTrackingEvent = (element) => {
    element.addEventListener("click", (event) => {
        if (event instanceof MouseEvent) {
            if (event.currentTarget instanceof HTMLElement) {
                const eventPayload = event.currentTarget.getAttribute("data-event");
                if (eventPayload) {
                    dispatchTrackingEvent(JSON.parse(eventPayload));
                }
            }
        }
    });
};
