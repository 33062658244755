import { debounce } from "~assets/frontend/_util/debounce";
const resizeBreadcrumb = () => {
    const breadcrumb = document.querySelector("nav[aria-label='Breadcrumb']");
    if (!(breadcrumb instanceof HTMLElement) || breadcrumb.children.length <= 2) {
        return;
    }
    // reset
    breadcrumb.children[0].classList.add("hidden");
    for (let i = 1; i < breadcrumb.children.length; i++) {
        const element = breadcrumb.children[i];
        element.style.width = "";
        element.style.overflow = "";
    }
    // Loop over children and hide them until the breadcrumb fits
    for (let i = 1; i < breadcrumb.children.length; i++) {
        if (breadcrumb.scrollWidth <= breadcrumb.clientWidth) {
            break;
        }
        // show the ellipses at the start
        breadcrumb.children[0].classList.remove("hidden");
        const element = breadcrumb.children[i];
        // Special handling for the last element
        if (i === breadcrumb.children.length - 1) {
            element.style.overflow = "hidden";
            element.style.textOverflow = "ellipsis";
            element.style.width = breadcrumb.clientWidth - breadcrumb.children[0].clientWidth + "px";
            break;
        }
        // Normal handling for the other elements
        element.style.width = "0";
        element.style.overflow = "hidden";
    }
};
const debouncedResizeBreadcrumb = debounce(resizeBreadcrumb);
export const breadcrumb = () => {
    window.addEventListener("resize", () => {
        // noinspection JSIgnoredPromiseFromCall
        debouncedResizeBreadcrumb();
    });
    // noinspection JSIgnoredPromiseFromCall
    debouncedResizeBreadcrumb();
};
