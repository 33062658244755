import { useEffect, useState } from "react";
import { IBEXA_PRODUCT_PREVIEW } from "~assets/frontend/_util/api-endpoints";
const productPreviewCache = new Map();
export function useProductPreview(contentId) {
    const [response, setResponse] = useState(null);
    useEffect(() => {
        preloadProductPreview(contentId, (productPreview) => {
            setResponse(productPreview);
        });
        return () => {
            setResponse(null);
        };
    }, [contentId]);
    return response;
}
export function preloadProductPreview(contentId, callback) {
    if (productPreviewCache.has(contentId.toString())) {
        const cacheItem = productPreviewCache.get(contentId.toString());
        callback && callback(cacheItem);
    }
    else {
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("X-SITEACCESS", location.pathname.split("/")[1]);
        const options = {
            method: "GET",
            headers: headers,
        };
        const url = `${IBEXA_PRODUCT_PREVIEW}${contentId}`;
        fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
            productPreviewCache.set(contentId.toString(), data.ProductPreview.preview);
            callback && callback(data.ProductPreview.preview);
        });
    }
}
