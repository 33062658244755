export var BREAKPOINT;
(function (BREAKPOINT) {
    BREAKPOINT["SM"] = "sm";
    BREAKPOINT["MD"] = "md";
    BREAKPOINT["LG"] = "lg";
    BREAKPOINT["XL"] = "xl";
})(BREAKPOINT || (BREAKPOINT = {}));
export const mapResponsiveImageSourceBreakpoint = (breakpoint) => {
    if (breakpoint === "small") {
        return BREAKPOINT.SM;
    }
    if (breakpoint === "medium") {
        return BREAKPOINT.MD;
    }
    if (breakpoint === "large") {
        return BREAKPOINT.LG;
    }
    if (breakpoint === "extraLarge") {
        return BREAKPOINT.XL;
    }
    throw new Error("Invalid argument breakpoint: " + breakpoint);
};
export const getPriorityForBreakpoint = (breakpoint) => {
    switch (breakpoint) {
        case "small":
        case BREAKPOINT.SM:
            return 1;
        case "medium":
        case BREAKPOINT.MD:
            return 2;
        case "large":
        case BREAKPOINT.LG:
            return 3;
        case "extraLarge":
        case BREAKPOINT.XL:
            return 4;
        default:
            return 0;
    }
};
export const minWidths = {
    [BREAKPOINT.SM]: 588,
    [BREAKPOINT.MD]: 755,
    [BREAKPOINT.LG]: 960,
    [BREAKPOINT.XL]: 1588,
};
