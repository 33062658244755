export const TRIGGER_SELECTOR_CAROUSEL = ".comp-carousel";
export const TRIGGER_SELECTOR_OFFCANVAS = "[data-offcanvas-headline]";
export const TRIGGER_SELECTOR_STAGE_CAROUSEL = '[data-inject="hero_stage_carousel"]';
export const TRIGGER_SELECTOR_LIGHTBOX = "[data-lightbox-image]";
export const TRIGGER_SELECTOR_INTERACTIVE_TABLE = "[data-interactive-table]";
export const TRIGGER_SELECTOR_SEARCH = '[data-inject="search"]';
export const TRIGGER_SELECTOR_GOOGLE_MAPS = "[data-inject='comp-google-maps']";
export const TRIGGER_SELECTOR_LOCATIONS = "[data-inject='mod-locations']";
export const TRIGGER_SELECTOR_CALENDAR_APP = "[data-inject='calendar-app']";
export const TRIGGER_SELECTOR_DEALERSEARCH = "[data-inject='mod-dealersearch']";
export const TRIGGER_SELECTOR_SAMPLEORDER = "[data-inject='mod-sampleorder']";
