import React from "react";
import { SecondaryButtonLink } from "~assets/frontend/elements/button-link/ButtonLink";
import { useProductPreview } from "~assets/frontend/_util/hooks/useProductPreview";
import Translator from "bazinga-translator";
import { ResponsiveImage } from "~assets/frontend/elements/responsive-image/ResponsiveImage";
import { Loading } from "~assets/frontend/frame/frm-search/components/Loading";
export const NavigationDetail = ({ contentId, href, title }) => {
    const productPreview = useProductPreview(contentId);
    if (productPreview !== null) {
        return (React.createElement(React.Fragment, null,
            productPreview.image && React.createElement(ResponsiveImage, Object.assign({}, productPreview.image, { sizes: { extraSmall: { w: 335, r: 1.48 } }, caption: "" })),
            React.createElement("p", { className: "mt-24 text-black-40" }, productPreview.category),
            React.createElement("p", { className: "headline-2" }, productPreview.title),
            productPreview.labels.length > 0 && (React.createElement("p", { className: "flex flex-wrap gap-3" }, productPreview.labels.map((label) => {
                return (React.createElement("span", { key: label, className: "py-2 bg-anthracite-10 px-6 text-16" }, label));
            }))),
            productPreview.text && React.createElement("p", { className: "mt-12" }, productPreview.text),
            React.createElement("div", { className: "mt-24" },
                React.createElement(SecondaryButtonLink, { _name: "ButtonStruct", _id: "", href: productPreview.href, label: `${Translator.trans("frm-navigation.product-preview.link-prefix", {}, "frontend")} ${productPreview.title}` }))));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement(Loading, { label: Translator.trans("frm-navigation.product-preview.loading", {}, "frontend") }),
            href && title && (React.createElement("div", { className: "mt-24" },
                React.createElement(SecondaryButtonLink, { _name: "ButtonStruct", _id: "", href: href, label: `${Translator.trans("frm-navigation.product-preview.link-prefix", {}, "frontend")} ${title}` })))));
    }
};
