var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from "react";
import { IBEXA_RESPONSIVE_IMAGE_ENDPOINT } from "~assets/frontend/_util/api-endpoints";
import { ResponsiveImageFormat } from "~assets/types/frontend/definitions/Images";
const cacheKeyForImageStruct = (imageStruct, sizes, format) => {
    let imageId = null;
    if (imageStruct.fieldValue && "id" in imageStruct.fieldValue) {
        imageId = imageStruct.fieldValue.id;
    }
    else if (imageStruct.fieldValue && "imageId" in imageStruct.fieldValue) {
        imageId = imageStruct.fieldValue.imageId;
    }
    if (!imageId) {
        // Fallback to stringified object
        imageId = JSON.stringify(imageStruct);
    }
    return imageId + JSON.stringify(Object.values(sizes)) + format;
};
export const resolveImageStruct = (imageStruct, sizes, format = ResponsiveImageFormat.WEBP, aCacheKey = null, abortController = null) => __awaiter(void 0, void 0, void 0, function* () {
    let cacheKey;
    if (aCacheKey === null) {
        cacheKey = cacheKeyForImageStruct(imageStruct, sizes, format);
    }
    else {
        cacheKey = aCacheKey;
    }
    const cachedItem = sessionStorage.getItem(cacheKey);
    if (typeof cachedItem === "string" && cachedItem !== "undefined") {
        return JSON.parse(cachedItem);
    }
    const bodyObject = {
        fieldValue: imageStruct.fieldValue,
        variationName: "scale_crop_" + format,
        sizes: sizes,
    };
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("X-SITEACCESS", location.pathname.split("/")[1]);
    const options = {
        method: "GET",
        headers: headers,
        signal: abortController === null || abortController === void 0 ? void 0 : abortController.signal,
    };
    const params = new URLSearchParams();
    params.append("body", JSON.stringify(bodyObject));
    const response = yield fetch(IBEXA_RESPONSIVE_IMAGE_ENDPOINT + "?" + params.toString(), options);
    const data = yield response.json();
    sessionStorage.setItem(cacheKey, JSON.stringify(data.ResponsiveImage));
    return data.ResponsiveImage;
});
export const useImageStruct = (imageStruct, sizes, format = ResponsiveImageFormat.WEBP) => {
    if (imageStruct === null) {
        return;
    }
    const [imageResponse, setImageResponse] = useState(null);
    const cacheKey = cacheKeyForImageStruct(imageStruct, sizes, format);
    useEffect(() => {
        if (!cacheKey) {
            return;
        }
        const controller = new AbortController();
        resolveImageStruct(imageStruct, sizes, format, cacheKey, controller)
            .then((data) => {
            if (!controller.signal.aborted && data) {
                setImageResponse(data);
            }
        })
            .catch((error) => {
            console.error("Error fetching image struct", error);
            setImageResponse(null);
        });
        return () => {
            controller.abort();
            // muss das sein im unmount? Darf das überhaupt sein? :-o
            setImageResponse(null);
        };
    }, [cacheKey]); // Cache key implicitly depends on imageStruct and sizes. If we them as dependencies directly, we get an infinite update loop as imageStruct changes every render somewhere.
    return imageResponse;
};
