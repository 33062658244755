import { isScrollingPrevented } from "~assets/frontend/_util/preventScrolling";
const checkHeaderState = (condensed) => {
    const headerContainer = document.querySelector("[data-header]");
    if (!headerContainer) {
        return;
    }
    const header = headerContainer.querySelector("header");
    document.body.dispatchEvent(new CustomEvent("header", { detail: { showCondensed: condensed } }));
    if (condensed) {
        headerContainer.classList.remove("normal");
        headerContainer.classList.add("condensed");
        if (header) {
            header.classList.remove("animate-header-out");
            header.classList.add("animate-header-in");
        }
    }
    else {
        if (header) {
            header.addEventListener("animationend", () => {
                headerContainer.classList.remove("condensed");
                headerContainer.classList.add("normal");
                header.classList.remove("animate-header-out");
            }, { once: true });
            header.classList.remove("animate-header-in");
            header.classList.add("animate-header-out");
        }
    }
};
const frmHeader = () => {
    let ticking = false;
    let condensed = false;
    const THRESHOLD = 300;
    let lastScrollPosition = window.scrollY;
    document.addEventListener("scroll", (event) => {
        if (isScrollingPrevented()) {
            return;
        }
        if (!ticking) {
            window.requestAnimationFrame(() => {
                const SCROLL_DOWN = window.scrollY > lastScrollPosition;
                const SCROLL_UP = window.scrollY < lastScrollPosition;
                if (SCROLL_DOWN) {
                    if (window.scrollY > THRESHOLD && condensed) {
                        condensed = false;
                        checkHeaderState(condensed);
                    }
                }
                if (SCROLL_UP) {
                    if (window.scrollY > THRESHOLD && !condensed) {
                        condensed = true;
                        checkHeaderState(condensed);
                    }
                    if (window.scrollY <= THRESHOLD && condensed) {
                        condensed = false;
                        checkHeaderState(condensed);
                    }
                }
                lastScrollPosition = window.scrollY;
                ticking = false;
            });
            ticking = true;
        }
    });
};
export default frmHeader;
