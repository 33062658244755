import React from "react";
import { Icon } from "../icon/Icon";
import { dispatchTrackingEvent } from "~assets/global/tracking/tracking-event";
import { getId } from "~assets/frontend/_util/getId";
export const GenericButtonLink = ({ label, showLabel = true, className, href, target, children, childrenBefore, trackingEvent, }) => {
    const trackClick = (event) => {
        if (trackingEvent) {
            dispatchTrackingEvent(trackingEvent);
        }
    };
    return (React.createElement("a", { onClick: trackClick, "aria-label": !showLabel ? label : undefined, href: href, target: target ? target : undefined, rel: target === "_blank" ? "noopener noreferrer" : "", className: className },
        childrenBefore,
        showLabel && React.createElement("span", { className: `${childrenBefore ? "ml-6" : ""} ${children ? "mr-6" : ""}` }, label),
        children));
};
export const PrimaryButtonLink = ({ label, showLabel = true, className, href, target, icon, trackingEvent }) => {
    // Special handling of external links: Add Icon if not provided and set target to _blank if not explicitly set
    if (href.startsWith("http")) {
        if (icon === null || icon === undefined) {
            icon = {
                identifier: "icon-external-link",
                position: "end",
            };
        }
        if (target === null || target === undefined) {
            target = "_blank";
        }
    }
    let iconStart, iconEnd = undefined;
    if (icon !== null && icon !== undefined) {
        const iconComponent = (React.createElement(Icon, { _id: getId(), _name: "IconStruct", identifier: icon.identifier, className: "before:bg-white group-hover/button:before:bg-white group-focus/button:before:bg-white group-disabled/button:before:bg-red-110 group-disabled/button:group-hover/button:before:bg-red-110" }));
        if (icon.position === "start") {
            iconStart = iconComponent;
        }
        if (icon.position === "end") {
            iconEnd = iconComponent;
        }
    }
    return (React.createElement(GenericButtonLink, { label: label, showLabel: showLabel, className: `group/button inline-flex items-center bg-red-100 px-12 py-12 text-white transition-colors disabled:text-opacity-70 hocus:bg-red-90 disabled:hocus:bg-red-100 disabled:hocus:text-opacity-70 ${className ? className : ""}`, href: href, target: target, children: iconEnd, childrenBefore: iconStart, trackingEvent: trackingEvent }));
};
export const SecondaryButtonLink = ({ label, showLabel = true, className, href, target, icon, trackingEvent }) => {
    // Special handling of external links: Add Icon if not provided and set target to _blank if not explicitly set
    if (href.startsWith("http")) {
        if (icon === undefined || icon === null) {
            icon = {
                identifier: "icon-external-link",
                position: "end",
            };
        }
        if (target === undefined || target === null) {
            target = "_blank";
        }
    }
    let iconStart, iconEnd = undefined;
    if (icon !== null && icon !== undefined) {
        const iconComponent = (React.createElement(Icon, { _id: getId(), _name: "IconStruct", identifier: icon.identifier, className: "before:bg-black-100 group-hover/button:before:bg-white group-focus/button:before:bg-white group-disabled/button:before:bg-black-40 group-disabled/button:group-hover/button:before:bg-black-40 dark:before:bg-white dark:group-hover/button:before:bg-black-100 dark:group-focus/button:before:bg-black-100 dark:group-hover/button:group-disabled/button:before:bg-black-40" }));
        if (icon.position === "start") {
            iconStart = iconComponent;
        }
        if (icon.position === "end") {
            iconEnd = iconComponent;
        }
    }
    return (React.createElement(GenericButtonLink, { label: label, showLabel: showLabel, className: `group/button inline-flex items-center bg-anthracite-100 bg-opacity-10 px-12 py-12 text-black-100 transition-colors disabled:text-black-40 hocus:bg-opacity-80 hocus:text-white disabled:hocus:bg-anthracite-100 disabled:hocus:bg-opacity-10 disabled:hocus:text-black-40 dark:bg-white dark:bg-opacity-10 dark:text-white dark:backdrop-blur-3 dark:disabled:text-black-40 dark:hocus:bg-white dark:hocus:bg-opacity-80 dark:hocus:text-black-100 dark:disabled:hocus:bg-white dark:disabled:hocus:bg-opacity-10 dark:disabled:hocus:text-black-40 ${className ? className : ""}`, href: href, target: target, children: iconEnd, childrenBefore: iconStart, trackingEvent: trackingEvent }));
};
