import React from "react";
export const LanguageSwitch = (props) => {
    /*
        Returns the current page in the translated version if available otherwise return root in language version
     */
    const getTranslatedPage = (label, href) => {
        const hreflang = document.querySelector(`link[hreflang=${label}]`);
        if (hreflang instanceof HTMLLinkElement) {
            return hreflang.href;
        }
        else {
            return href;
        }
    };
    return (React.createElement("ul", { className: "flex flex-row gap-x-24" }, Object.entries(props.languages).map(([label, href]) => {
        const isLanguageActive = document.documentElement.lang.startsWith(label);
        return (React.createElement("li", { key: label },
            React.createElement("a", { className: `${isLanguageActive ? "text-red-100 underline" : ""} uppercase underline-offset-[6px] transition-colors hover:text-red-100 hover:underline`, href: getTranslatedPage(label, href) }, label)));
    })));
};
