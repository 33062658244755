import React from "react";
import { Icon } from "../icon/Icon";
import { getId } from "~assets/frontend/_util/getId";
export const GenericButton = ({ label, showLabel = true, className, onClick, children, childrenBefore, disabled = false, }) => {
    return (React.createElement("button", { disabled: disabled, "aria-label": !showLabel ? label : undefined, onClick: onClick, className: className },
        childrenBefore,
        showLabel && React.createElement("span", { className: `${childrenBefore ? "ml-6" : ""} ${children ? "mr-6" : ""}` }, label),
        children));
};
export const PrimaryButton = ({ label, showLabel = true, className, icon, onClick, disabled = false }) => {
    let iconStart, iconEnd = undefined;
    if (icon !== undefined) {
        const iconComponent = (React.createElement(Icon, { _id: getId(), _name: "IconStruct", identifier: icon.identifier, className: "before:bg-white group-hover/button:before:bg-white group-focus/button:before:bg-white group-disabled/button:before:bg-red-110 group-disabled/button:group-hover/button:before:bg-red-110" }));
        if (icon.position === "start") {
            iconStart = iconComponent;
        }
        else {
            iconEnd = iconComponent;
        }
    }
    return (React.createElement(GenericButton, { label: label, showLabel: showLabel, className: `group/button inline-flex items-center bg-red-100 px-12 py-12 text-18 text-white transition-colors disabled:text-opacity-70 hocus:bg-red-90 disabled:hocus:bg-red-100 disabled:hocus:text-opacity-70 ${className ? className : ""}`, onClick: onClick, disabled: disabled, childrenBefore: iconStart, children: iconEnd }));
};
export const SecondaryButton = ({ label, showLabel = true, className, icon, onClick, disabled = false }) => {
    let iconStart, iconEnd = undefined;
    if (icon !== undefined) {
        const iconComponent = (React.createElement(Icon, { _id: getId(), _name: "IconStruct", identifier: icon.identifier, className: "before:bg-black-100 group-hover/button:before:bg-white group-focus/button:before:bg-white group-disabled/button:before:bg-black-40 group-disabled/button:group-hover/button:before:bg-black-40 dark:before:bg-white dark:group-hover/button:before:bg-black-100 dark:group-focus/button:before:bg-black-100 dark:group-hover/button:group-disabled/button:before:bg-black-40" }));
        if (icon.position === "start") {
            iconStart = iconComponent;
        }
        else {
            iconEnd = iconComponent;
        }
    }
    return (React.createElement(GenericButton, { label: label, showLabel: showLabel, className: `group/button inline-flex items-center bg-anthracite-100 bg-opacity-10 px-12 py-12 text-18 text-black-100 transition-colors disabled:text-black-40 hocus:bg-opacity-80 hocus:text-white disabled:hocus:bg-anthracite-100 disabled:hocus:bg-opacity-10 disabled:hocus:text-black-40 dark:bg-white dark:bg-opacity-10 dark:text-white dark:backdrop-blur-3 dark:disabled:text-black-40 dark:hocus:bg-white dark:hocus:bg-opacity-80 dark:hocus:text-black-100 dark:disabled:hocus:bg-white dark:disabled:hocus:bg-opacity-10 dark:disabled:hocus:text-black-40 ${className ? className : ""}`, onClick: onClick, disabled: disabled, childrenBefore: iconStart, children: iconEnd }));
};
export const TransparentIconButton = ({ label, className, iconIdentifier, onClick, disabled = false, size = "48" }) => {
    let sizeClass = "w-48 h-48 px-12 py-12 ";
    let iconSizeClass = "";
    if (size == "36") {
        sizeClass = "w-36 h-36 px-6 py-6 ";
    }
    if (size == "60") {
        sizeClass = "w-60 h-60 px-12 py-12 ";
    }
    if (size == "96") {
        sizeClass = "w-96 h-96 px-12 py-12 ";
        iconSizeClass = "before:w-60 before:h-60 ";
    }
    return (React.createElement(GenericButton, { label: label, showLabel: false, className: "group/button inline-flex items-center justify-center transition-colors " +
            sizeClass +
            "bg-transparent bg-opacity-100 " +
            "hocus:enabled:bg-anthracite-100 hocus:enabled:bg-opacity-10 " +
            "dark:hocus:enabled:bg-white dark:hocus:enabled:bg-opacity-80 dark:hocus:enabled:backdrop-blur-3 " +
            (className ? className : ""), onClick: onClick, disabled: disabled, childrenBefore: React.createElement(Icon, { _id: getId(), _name: "IconStruct", identifier: iconIdentifier, className: "before:bg-black-100 group-disabled/button:before:bg-black-40 " +
                // No different hover style for light mode
                "dark:before:bg-white " +
                "dark:group-hover/button:group-enabled/button:before:bg-black-100 dark:group-focus/button:group-enabled/button:before:bg-black-100 " +
                iconSizeClass }) }));
};
export const SecondaryIconButton = ({ label, className, iconIdentifier, onClick, disabled = false, size = "48" }) => {
    let sizeClass = "w-48 h-48 px-12 py-12 ";
    if (size == "36") {
        sizeClass = "w-36 h-36 px-6 py-6 ";
    }
    return (React.createElement(GenericButton, { label: label, showLabel: false, className: sizeClass +
            ` group/button inline-flex items-center bg-anthracite-100 bg-opacity-10 text-black-100 transition-colors disabled:text-black-40 hocus:bg-opacity-80 hocus:text-white disabled:hocus:bg-anthracite-100 disabled:hocus:bg-opacity-10 disabled:hocus:text-black-40 dark:bg-white dark:bg-opacity-10 dark:text-white dark:backdrop-blur-3 dark:disabled:text-black-40 dark:hocus:bg-white dark:hocus:bg-opacity-80 dark:hocus:text-black-100 dark:disabled:hocus:bg-white dark:disabled:hocus:bg-opacity-10 dark:disabled:hocus:text-black-40 ${className ? className : ""}`, onClick: onClick, disabled: disabled, childrenBefore: React.createElement(Icon, { _id: getId(), _name: "IconStruct", identifier: iconIdentifier, className: "before:bg-black-100 group-hover/button:before:bg-white group-focus/button:before:bg-white group-disabled/button:before:bg-black-40 group-disabled/button:group-hover/button:before:bg-black-40 dark:before:bg-white dark:group-hover/button:before:bg-black-100 dark:group-focus/button:before:bg-black-100 dark:group-hover/button:group-disabled/button:before:bg-black-40" }) }));
};
export const WhiteIconButton = ({ label, className, iconIdentifier, onClick, disabled = false, size = "48" }) => {
    let sizeClass = "w-48 h-48 px-12 py-12 ";
    if (size == "36") {
        sizeClass = "w-36 h-36 px-6 py-6 ";
    }
    return (React.createElement(GenericButton, { label: label, showLabel: false, className: "group/button inline-flex items-center transition-colors " +
            sizeClass +
            "bg-white bg-opacity-100 " +
            "hocus:enabled:bg-anthracite-100 hocus:enabled:bg-opacity-10 " +
            "dark:hocus:enabled:bg-anthracite-15 dark:hocus:enabled:bg-opacity-80 dark:hocus:enabled:backdrop-blur-3 " +
            (className ? className : ""), onClick: onClick, disabled: disabled, childrenBefore: React.createElement(Icon, { _id: getId(), _name: "IconStruct", identifier: iconIdentifier, className: "before:bg-black-100 group-disabled/button:before:bg-black-40 " }) }));
};
