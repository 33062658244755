import React from "react";
import { Icon } from "~assets/frontend/elements/icon/Icon";
import { getId } from "~assets/frontend/_util/getId";
export const NavigationItemNormal = (props) => {
    const renderButton = (props.items && props.items.length > 0) || false;
    return (React.createElement(React.Fragment, null,
        !renderButton && props.href && (React.createElement("a", { className: "flex py-12 hocus:text-red-100", href: props.href }, props.title)),
        renderButton && (React.createElement("button", { className: `group/button text-black flex w-full justify-between py-12 text-left hocus:text-red-100 ${props.selectedLocation === props.locationId ? "text-red-100 underline underline-offset-[6px]" : ""}`, onClick: () => {
                props.onDetail && props.onDetail(null);
                props.onSelect && props.onSelect(props.locationId);
            } },
            props.title,
            React.createElement(Icon, { _id: getId(), _name: "IconStruct", identifier: "icon-arrow-right", className: `before:bg-black-40 group-hover/button:before:bg-red-100 ${props.selectedLocation === props.locationId ? "before:bg-red-100" : ""}` })))));
};
