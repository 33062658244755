const usedIds = [];
const generateID = () => {
    return "uuid_" + (Date.now() + Math.floor(Math.random() * 100));
};
export const getId = () => {
    const id = generateID();
    // if (indexOf(usedIds, id) === -1) {
    //     usedIds.push(id);
    //     return id;
    // }
    // return getId();
    if (usedIds.includes(id)) {
        // ID already exists, try again
        return getId();
    }
    usedIds.push(id);
    return id;
};
