import { useEffect, useState } from "react";
import { debounce } from "~assets/frontend/_util/debounce";
import { BREAKPOINT, minWidths } from "~assets/frontend/_util/breakpoints";
function calculateBreakpoint() {
    const width = window.innerWidth;
    if (width >= minWidths[BREAKPOINT.XL])
        return BREAKPOINT.XL;
    if (width >= minWidths[BREAKPOINT.LG])
        return BREAKPOINT.LG;
    if (width >= minWidths[BREAKPOINT.MD])
        return BREAKPOINT.MD;
    if (width >= minWidths[BREAKPOINT.SM])
        return BREAKPOINT.SM;
    return width;
}
const initialBreakpoint = calculateBreakpoint();
export function useBreakpoint() {
    const [breakpoint, setBreakpoint] = useState(initialBreakpoint);
    useEffect(() => {
        if (breakpoint !== calculateBreakpoint()) {
            setBreakpoint(calculateBreakpoint());
        }
        const updateBreakpoint = () => {
            setBreakpoint(calculateBreakpoint());
        };
        const debouncedUpdate = debounce(updateBreakpoint, 50, { maxWait: 500 });
        const debouncedUpdateCaller = () => debouncedUpdate();
        window.addEventListener("resize", debouncedUpdateCaller);
        return () => {
            debouncedUpdate.cancel();
            window.removeEventListener("resize", debouncedUpdateCaller);
        };
    }, []);
    return breakpoint;
}
