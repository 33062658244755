export const preventScrolling = (doNotScroll) => {
    if (isScrollingPrevented() !== doNotScroll) {
        if (doNotScroll) {
            // scroll -> no scroll
            const scrollTop = window.scrollY - 1;
            document.body.classList.add("overflow-y-hidden", "scrollbar-invisible");
            document.documentElement.classList.add("scrollbar-invisible"); // For Firefox
            // to keep the scroll bar and prevent horizontal page jumping (i.e. logo!)
            document.body.style.height = "calc(100vh + 1px)";
            document.body.scrollTo(0, scrollTop);
        }
        else {
            // no-scroll -> scroll
            const scrollTop = document.body.scrollTop + 1; // wg. dem 100vh + 1 hack. Theoretisch könnte man noch die window scrollposition dazunehmen für 100% ...
            document.body.classList.remove("overflow-y-hidden", "scrollbar-invisible");
            document.documentElement.classList.remove("scrollbar-invisible"); // For Firefox
            document.body.style.height = "auto";
            if (document.documentElement.classList.contains("scroll-smooth")) {
                document.documentElement.classList.remove("scroll-smooth");
                window.scrollTo(0, scrollTop);
                document.documentElement.classList.add("scroll-smooth");
            }
            else {
                window.scrollTo(0, scrollTop);
            }
        }
        document.dispatchEvent(new CustomEvent("preventscroll", { detail: doNotScroll }));
    }
};
export const isScrollingPrevented = () => {
    return document.body.classList.contains("overflow-y-hidden");
};
