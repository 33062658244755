import { useEffect, useState } from "react";
export const formatText = (text) => {
    return text.replaceAll(/\*([^*]+)\*/g, '<span class="font-500">$1</span>');
};
export const formatNewLine = (text) => {
    return text.replaceAll(/(\r\n|\n|\r)/g, "<br />");
};
export const useFormatter = (text) => {
    const [formattedText, setFormattedText] = useState(null);
    useEffect(() => {
        setFormattedText(formatNewLine(formatText(text)));
    }, [text]);
    return formattedText;
};
