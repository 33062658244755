import React, { useEffect, useState } from "react";
import { Icon } from "~assets/frontend/elements/icon/Icon";
import Translator from "bazinga-translator";
import { preventScrolling } from "~assets/frontend/_util/preventScrolling";
import { NavigationList } from "~assets/frontend/frame/frm-navigation/components/NavigationList";
import { NavigationOverlay } from "~assets/frontend/frame/frm-navigation/components/NavigationOverlay";
import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import { createPortal } from "react-dom";
import { useBreakpoint } from "~assets/frontend/_util/hooks/useBreakpoint";
import { BREAKPOINT } from "~assets/frontend/_util/breakpoints";
import { getId } from "~assets/frontend/_util/getId";
import { LanguageSwitch } from "~assets/frontend/frame/frm-navigation/components/LanguageSwitch";
export const BurgerNavigation = ({ items, languages }) => {
    const [showMenu, setShowMenu] = useState(false);
    const headerItems = items.filter((item) => item.priority <= 50);
    const mainItems = items.filter((item) => item.priority > 50 && item.priority <= 100);
    const additionalItems = items.filter((item) => item.priority > 100);
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };
    useEffect(() => {
        if (showMenu) {
            preventScrolling(showMenu);
            const handleKeyDown = (event) => {
                if (event.code === "Escape") {
                    setShowMenu(false);
                }
            };
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [showMenu]);
    const isXl = useBreakpoint() === BREAKPOINT.XL;
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { className: "group/burger mb-0 inline-block p-12 transition-colors dark:hover:bg-white dark:hover:bg-opacity-80", onClick: toggleMenu, "aria-label": Translator.trans("frm-navigation.aria.toggle-menu", {}, "frontend") },
            React.createElement(Icon, { _id: getId(), _name: "IconStruct", identifier: "icon-menu", className: "transition-colors before:bg-black-100 group-hover/burger:before:bg-red-100 dark:before:bg-white group-hover/burger:dark:before:bg-black-100 group-[.condensed]:dark:before:bg-black-100 group-[.condensed]:dark:hover:before:bg-red-100" })),
        createPortal(React.createElement(MotionConfig, { transition: { duration: 0.6, ease: "easeOut" } },
            React.createElement(AnimatePresence, { onExitComplete: () => {
                    if (!showMenu) {
                        preventScrolling(showMenu);
                    }
                } }, showMenu && (React.createElement(motion.div, { className: "fixed inset-0 z-navigation h-full w-full", "aria-label": Translator.trans(isXl ? "frm-navigation.aria.additional-navigation" : "frm-navigation.aria.main-navigation", {}, "frontend") },
                React.createElement(NavigationOverlay, { onClose: () => {
                        setShowMenu(false);
                    } },
                    React.createElement(motion.div, { key: "children", initial: { x: "100%" }, animate: { x: 0 }, exit: { x: "100%" }, layout: "position", className: "absolute right-0 top-0 z-dimmer h-screen overflow-hidden bg-white" },
                        React.createElement("nav", { className: "relative flex h-screen flex-col gap-24" },
                            React.createElement(NavigationList, { layer: 0, label: null, href: null, selected: null, headerItems: headerItems, mainItems: mainItems, additionalItems: additionalItems, layered: true, onClose: () => {
                                    setShowMenu(false);
                                }, languageList: languages && React.createElement(LanguageSwitch, { languages: languages }) })))))))), document.body, "burger-navigation")));
};
