let globalScrollId = undefined;
let globalShowId = undefined;
export function anchorScrolling(pagereload = false) {
    const hash = window.location.hash;
    if (hash.startsWith("#" + ANCHOR_TYPE.SCROLL)) {
        scrollToAnchor(hash.replace("#" + ANCHOR_TYPE.SCROLL + "-", ""));
    }
    else if (hash.startsWith("#" + ANCHOR_TYPE.SHOW)) {
        if (pagereload) {
            window.addEventListener("load", () => {
                scrollToAnchor(hash.replace("#" + ANCHOR_TYPE.SHOW + "-", ""));
            });
        }
        globalShowId = hash.replace("#" + ANCHOR_TYPE.SHOW + "-", "");
    }
    else {
        scrollToAnchor(hash.replace("#", ""));
    }
}
function scrollToAnchor(id) {
    const inPageNavigation = !!(document.getElementById("in-page-navigation") && document.querySelector("[data-nav]"));
    const target = document.getElementById(id);
    if (target) {
        // Wenn targetPosition > 0 ist, dann wird nach unten gescrollt.
        // In dem Fall wird das Menu ausgeblendet und nur die In-Page-Navi ist sichtbar
        // Das Offset kann also kleiner sein.
        // Im anderne Fall wenn man nach oben scrollt wird das Menu eingeblendet das Offset muss größer sein.
        const targetPosition = target.getBoundingClientRect().top;
        let offset = targetPosition > 0 ? 100 : 200;
        if (inPageNavigation) {
            offset += 60;
        }
        globalScrollId = undefined;
        window.scrollTo({
            behavior: "smooth",
            top: window.scrollY + targetPosition - offset,
        });
    }
    else {
        globalScrollId = id;
    }
}
export var ANCHOR_TYPE;
(function (ANCHOR_TYPE) {
    ANCHOR_TYPE["SCROLL"] = "target";
    ANCHOR_TYPE["SHOW"] = "show";
})(ANCHOR_TYPE || (ANCHOR_TYPE = {}));
export function isShownAnchorActive(id) {
    return globalShowId === id;
}
export function handleDelayedScroll(id) {
    if (globalScrollId === id) {
        scrollToAnchor(id);
    }
}
export function setAnchor(type, id) {
    window.location.hash = type + "-" + id;
    anchorScrolling();
}
/*
    This function handles anchor links in html. Once clicked we handle set the hash and manipulate it so the browser does not scroll.
    This also fixes the issue with the calendarApp which is using an anchor with href="#" when links are initialized when loaded
 */
export function handleDefaultAnchorLinks(list) {
    const anchorLinks = list ? list : document.querySelectorAll("a[href='^#']");
    anchorLinks.forEach((link) => link.addEventListener("click", (event) => {
        if (event.currentTarget instanceof HTMLAnchorElement) {
            const id = event.currentTarget.getAttribute("href");
            if (id) {
                event.preventDefault();
                setAnchor(ANCHOR_TYPE.SCROLL, id.replace("#", ""));
            }
        }
    }));
}
