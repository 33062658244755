import React, { useEffect, useState } from "react";
import Translator from "bazinga-translator";
import { NavigationDetail } from "~assets/frontend/frame/frm-navigation/components/NavigationDetail";
import { Icon } from "~assets/frontend/elements/icon/Icon";
import { AnimatePresence, motion } from "framer-motion";
import { NavigationSection } from "~assets/frontend/frame/frm-navigation/components/NavigationSection";
import { WhiteIconButton } from "~assets/frontend/elements/button/Buttons";
import { useBreakpoint } from "~assets/frontend/_util/hooks/useBreakpoint";
import { BREAKPOINT } from "~assets/frontend/_util/breakpoints";
import { preloadProductPreview } from "~assets/frontend/_util/hooks/useProductPreview";
import { getId } from "~assets/frontend/_util/getId";
export const NavigationList = ({ label, href, headerItems = [], mainItems, additionalItems = [], selected, parentLabel, onSelect, onDetail, inline, onClose, onCloseLayer, layered = false, layer, additionalClassName, languageList, }) => {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [animationFinished, setAnimationFinished] = useState(true);
    useEffect(() => {
        setSelectedLocation(selected);
    }, [selected]);
    const selectedItem = [...headerItems, ...mainItems, ...additionalItems].find((item) => item.locationId === selectedLocation);
    const isXl = useBreakpoint() === BREAKPOINT.XL;
    [...headerItems, ...mainItems, ...additionalItems].forEach((item) => {
        if (item.display === "preview" && isXl) {
            preloadProductPreview(item.contentId);
        }
    });
    // Warum ruft das ein Callback auf wenn ein Item ausgewählt ist :-O
    useEffect(() => {
        if (selectedItem && selectedItem.display === "preview" && onDetail) {
            onDetail(React.createElement(NavigationDetail, { contentId: selectedItem.contentId, href: selectedItem.href || undefined, title: selectedItem.title }));
        }
    }, [selectedItem]);
    const changeNavigation = (locationId) => {
        setSelectedLocation(locationId);
        onSelect && onSelect(locationId);
    };
    const zIndices = {
        0: "z-nav-level-1",
        1: "z-nav-level-2",
        2: "z-nav-level-3",
        3: "z-nav-level-4",
        4: "z-nav-level-5",
        5: "z-nav-level-6",
        6: "z-nav-level-7",
    };
    const zIndex = layered ? zIndices[Object.keys(zIndices).length - layer] || "z-nav-level-7" : zIndices[layer] || "z-nav-level-7";
    const List = (React.createElement("div", { className: `h-full bg-white ${inline
            ? ""
            : "overflow-auto " + (layered ? "w-screen max-w-[630px] p-24 md:max-w-[625px] md:pl-60 lg:pl-96" : "w-[320px] pb-0 pl-36 pr-24 pt-60")} ${additionalClassName ? additionalClassName : ""}` },
        layered && (React.createElement("div", { className: "mb-12 flex w-full items-center gap-12" },
            React.createElement("a", { href: "/", className: "md:hidden" },
                React.createElement("svg", { width: "28", height: "33", viewBox: "6 0 40 60", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("path", { className: "fill-red-100", d: "M19.4201 30.7097L26.25 29.8864V26.8401L13.0839 28.4868V31.5331L9.62774 29.9688L9.05172 28.6514V27.0048V26.5931L18.5149 25.4405L18.1034 18.113L21.1481 25.1112L22.1356 25.0288L25.4271 26.5108L24.7688 25.0288H24.1105L22.547 18.36L17.7743 9.71514L13.3307 8.5625L14.7296 6.7512L13.6599 0.49399L10.0392 0L8.06426 2.71695L8.39342 3.95192L7.89969 4.03426L8.39342 6.25721L8.88715 6.17488L9.29859 8.89183L3.94984 11.1148L0 23.7115L6.00705 29.2278L6.74765 30.7097L7.48824 31.1214L7.15909 44.2945L6.33621 46.4351L6.00705 56.3149L4.279 58.6202V59.9375H8.96944V57.3029L13.5776 36.5553L16.2931 43.9651V46.6821L19.0909 54.9976L19.3378 57.3852L25.098 58.4555V56.8912L21.8064 54.1743L19.4201 30.7097ZM4.44357 23.2999L5.84248 19.595L6.99451 26.7578L4.44357 23.2999Z" }))),
            React.createElement("button", { className: `${!!onCloseLayer ? "" : "invisible"} group/button flex items-center bg-white py-12 text-left hocus:text-red-100 hocus:underline hocus:underline-offset-[6px]`, onClick: onCloseLayer },
                React.createElement(Icon, { _id: getId(), _name: "IconStruct", identifier: "icon-arrow-left", className: "mr-6 before:bg-black-100 group-hover/button:before:bg-red-100" }),
                parentLabel,
                parentLabel === undefined && Translator.trans("frm-navigation.back", {}, "frontend")))),
        label && layered && React.createElement("p", { className: `headline-2 my-24 flex py-12` }, label),
        React.createElement("div", { className: "flex w-full max-w-[335px] flex-col gap-24" },
            label && href && (React.createElement("a", { href: href, className: `flex py-12 hocus:text-red-100` }, Translator.trans("frm-navigation.all", { label: label }, "frontend"))),
            !isXl && headerItems && headerItems.length > 0 && (React.createElement(NavigationSection, { items: headerItems, selectedLocation: selectedLocation, onSelect: changeNavigation, layer: layer, className: `w-full p-0`, onDetail: onDetail })),
            React.createElement(NavigationSection, { items: mainItems, selectedLocation: selectedLocation, onSelect: changeNavigation, layer: layer, className: "w-full p-0", onDetail: onDetail }),
            additionalItems && additionalItems.length > 0 && (React.createElement(NavigationSection, { items: additionalItems, selectedLocation: selectedLocation, onSelect: changeNavigation, layer: layer, className: "w-full p-0", onDetail: onDetail })),
            layered && languageList),
        layered && (React.createElement("div", { className: "dark absolute right-24 top-24 z-navigation" },
            React.createElement(WhiteIconButton, { _id: getId(), _name: "IconButtonStruct", onClick: () => {
                    setSelectedLocation(null);
                    onClose && onClose();
                }, label: Translator.trans("frm-navigation.aria.close", {}, "frontend"), iconIdentifier: "icon-close" })))));
    if (layered) {
        return (React.createElement(React.Fragment, null,
            List,
            React.createElement(AnimatePresence, null, selectedItem && selectedItem.display === "normal" && selectedItem.items && selectedItem.locationId === selectedLocation && (React.createElement(motion.div, { className: `${zIndex} absolute top-0 h-full`, key: `item-${selectedLocation}`, initial: { x: "100%" }, animate: { x: 0 }, exit: { x: "100%" }, layout: "position" },
                React.createElement(NavigationList, { selected: selectedItem.locationId, mainItems: selectedItem.items, label: selectedItem.title, parentLabel: label || undefined, layered: layered, href: selectedItem.href, onDetail: onDetail, layer: layer + 1, onClose: () => {
                        setSelectedLocation(null);
                        onClose && onClose();
                    }, onCloseLayer: () => {
                        setSelectedLocation(null);
                    } }))))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(motion.div, { key: `item-${layer}`, initial: { x: "-100%" }, animate: { x: 0 }, exit: { x: `-${(layer + 1) * 100}%` }, onAnimationComplete: () => {
                setAnimationFinished(true);
            }, onAnimationStart: () => {
                setAnimationFinished(false);
            }, className: `${zIndex}` },
            !selectedItem && !inline && (React.createElement("div", { className: "dark absolute right-0 top-0" },
                React.createElement(WhiteIconButton, { _id: getId(), _name: "IconButtonStruct", onClick: () => {
                        setSelectedLocation(null);
                        onClose && onClose();
                    }, label: Translator.trans("frm-navigation.aria.close", {}, "frontend"), iconIdentifier: "icon-close" }))),
            List),
        React.createElement(AnimatePresence, null, animationFinished &&
            selectedItem &&
            selectedItem.display === "normal" &&
            selectedItem.items &&
            selectedItem.locationId === selectedLocation && (React.createElement(NavigationList, { selected: selectedItem.locationId, mainItems: selectedItem.items, label: selectedItem.title, parentLabel: label || undefined, layered: layered, href: selectedItem.href, onDetail: onDetail, layer: layer + 1, onClose: () => {
                setSelectedLocation(null);
                onClose && onClose();
            }, onCloseLayer: () => {
                setSelectedLocation(null);
            } })))));
};
