import { dispatchTrackingEvent } from "~assets/global/tracking/tracking-event";
import { ANCHOR_TYPE, isShownAnchorActive, setAnchor } from "~assets/frontend/_util/anchor-scrolling";
const changeTabsListener = (e) => {
    const target = e.target instanceof HTMLElement && e.target;
    if (!target)
        return;
    const trackingEvent = {
        name: "ui",
        payload: {
            ui_function: "navigate",
            ui_action: null,
            ui_component: "mod-tabs",
            ui_target: e.target.textContent,
        },
    };
    if (target.getAttribute("aria-selected") === "false") {
        dispatchTrackingEvent(trackingEvent);
    }
    setAnchor(ANCHOR_TYPE.SHOW, target.id);
    changeTabs(target);
};
const changeTabs = (target) => {
    var _a, _b;
    const parent = target.closest("[role='tablist']");
    const grandparent = parent === null || parent === void 0 ? void 0 : parent.closest("[data-inject='mod-tabs']");
    // Remove all current selected tabs
    parent === null || parent === void 0 ? void 0 : parent.querySelectorAll('[aria-selected="true"]').forEach((t) => t.setAttribute("aria-selected", "false"));
    // Set this tab as selected
    target.setAttribute("aria-selected", "true");
    // Hide all tab panels
    grandparent === null || grandparent === void 0 ? void 0 : grandparent.querySelectorAll('[role="tabpanel"]').forEach((p) => p.setAttribute("hidden", "true"));
    // Show the selected panel
    (_b = (_a = grandparent === null || grandparent === void 0 ? void 0 : grandparent.parentNode) === null || _a === void 0 ? void 0 : _a.querySelector(`#${target.getAttribute("aria-controls")}`)) === null || _b === void 0 ? void 0 : _b.removeAttribute("hidden");
};
export const modTabs = () => {
    const tabComponents = document.querySelectorAll("[data-inject='mod-tabs']");
    tabComponents.forEach((tabComponent) => {
        const tabs = tabComponent.querySelectorAll('[role="tab"]');
        const tabList = tabComponent.querySelector('[role="tablist"]');
        // Add a click event handler to each tab
        tabs.forEach((tab) => {
            tab.addEventListener("click", changeTabsListener);
            if (isShownAnchorActive(tab.id)) {
                if (tab instanceof HTMLElement) {
                    changeTabs(tab);
                }
            }
        });
        // Enable arrow navigation between tabs in the tab list
        let tabFocus = 0;
        tabList === null || tabList === void 0 ? void 0 : tabList.addEventListener("keydown", (e) => {
            // Move right
            if (!(e instanceof KeyboardEvent))
                return;
            if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
                const activeTab = tabs[tabFocus];
                if (!(activeTab instanceof HTMLButtonElement))
                    return;
                activeTab.setAttribute("tabindex", "-1");
                if (e.key === "ArrowRight") {
                    tabFocus++;
                    // If we're at the end, go to the start
                    if (tabFocus >= tabs.length) {
                        tabFocus = 0;
                    }
                    // Move left
                }
                else if (e.key === "ArrowLeft") {
                    tabFocus--;
                    // If we're at the start, move to the end
                    if (tabFocus < 0) {
                        tabFocus = tabs.length - 1;
                    }
                }
                const nextTab = tabs[tabFocus];
                if (!(nextTab instanceof HTMLButtonElement))
                    return;
                nextTab.setAttribute("tabindex", "0");
                nextTab.focus();
            }
        });
    });
};
